import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Today is our free Partner wod at 9:00 & 10:15 so bring a friend. If
you aren’t a member but would like to come email:
Daniel\\@crossfittheville.org.`}</em></strong></p>
    <p>{`Partner WOD`}</p>
    <p>{`Run to turf (240m)\\
280ft Sled pull (90/45)\\
Run to front door (240m)\\
60-Partner Burpees\\
60-Goblet Squats (53/35) (Rx+70/53)\\
60-DB Single Arm Renegade Row (35/25) (Rx+50/35)\\
120-Sit-ups\\
Run to turf (240m)\\
280ft Sled Pull\\
Run to front door (240m)\\
Time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      